<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import MakeTransferDialog from './components/make_transfer_dialog.vue'
import ManualTransferDialog from './components/setup_transfer_dialog.vue'

import Choices from "choices.js";

import {getCashApi} from '@/api/cash'

/**
 * User list component
 */
export default {
  page: {
    title: "Bank Transfer", 
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],

      account_list: [],
      acount_choice_obj: {},
      current_account_type: "",
      remit_bank_account :{},
      receive_bank_account :{},

      selected_transaction_list: [],
      list_type : 'RECORDS',
      bt_ids : [],
      title: "Bank Transfer",
      items: [
        {
          text: "Bank & Cash",
          href: "/",
        },
        {
          text: "Bank Transfer",
          href: "/",
          active : true
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      total_amount : 0,
      sortDesc: false,
      fields: [
       {
          key: "C",
          label : ''
        },
        {
          key: "ID",
          sortable: true,
        },
        {key: 'Icon', label : ''},
        {
          key: "From",
          sortable: true,
        },
        
        {
          key: "To",
          sortable: false,
        },

        {
          key: "DueDate",
          sortable: false,
        },
        {
          key: "Amount",
          sortable: true,
        },

        {
          key: "Reference",
          sortable: false,
        },

        {
          key: "Status",
          sortable: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    MakeTransferDialog,
    ManualTransferDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

    transfer_btn_flag() {
      if (this.selected_transaction_list.length <= 0) {
        return true
      }

      let pending_flag = true
      for (let r of this.selected_transaction_list) {
        if (r.status != 'PENDING') {
          pending_flag = false
          break
        }
      }
      if (this.selected_transaction_list.length == 1 && pending_flag) {
        return false
      }
      let fir = this.selected_transaction_list[0]
      let new_array = this.selected_transaction_list.filter((e) => e.from != fir.from && e.to != fir.ti)
      return !(this.selected_transaction_list.length != new_array.length && pending_flag)
    }
  },

  created() {

    getCashApi().bank_account_list().then((res) =>{
      this.account_list.push({
        label: "",
        account: ""
      })
      if (res.errCode == 0) {
        res.data.accounts.map((a)=>{
          this.account_list.push({
            label : a.name,
            value : a.a_account,
            account : a.a_account
          })
        })
        this.acount_choice_obj.setChoices(this.account_list)
      }
    })

  },
  mounted() {
    this.acount_choice_obj= new Choices('#bank_account', {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
    })
  
  },
  methods: {

    queryList() {
      getCashApi().transfer_records_list({action: "BF", account : this.current_account_type, page : {page : this.currentPage, page_size: this.perPage}}).then((res)=>{
        this.list = []
        this.total_amount  = 0
        res.data.map((o) =>{
        
          this.list.push({
            id               : o.id,
            from             : o.remit_bank,
            to               : o.receive_bank,
            transaction_date : o.transaction_date,
            amount           : o.amount?o.amount : 0,
            ref_type         : o.ref_type,
            remarks          : o.remarks?o.remarks : '',
            ref_id           : o.ref_id,
            code             : o.code,
            status           : o.status,
            details          : o.details,
            cash_record      : o.cash_record,
            reference        : this.get_reference(o)
          })
          this.total_amount += o.amount||0
         
        })

        this.totalRows = res.page.total;
      })
    },



    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },


    changeAccount(evt) {
      this.selected_transaction_list =  []
      this.current_account_type = evt.target.value
      this.queryList()

    },

    open_transfer_dialog() {
     
     
    },

    onRecordsUpdate(evt, item) {
      
      if (evt.target.checked == true) {
        let d = this.selected_transaction_list.find((e)=> e.id == item.id)
        if (!d) {
          this.selected_transaction_list.push(item)
        }
      } else {
        this.selected_transaction_list = this.selected_transaction_list.filter((e)=> e.id != item.id)
      }
    },

    onTransfered() {
      this.selected_transaction_list =  []
      this.queryList()
      this.$bvModal.hide('modal-make-transfer-dialog')
    },

    onSelectAll(evt) {
      if (evt.target.checked == true) {
        this.selected_transaction_list = []
        this.selected_transaction_list.push(...this.list)
      } else {
        this.selected_transaction_list = []
      }

      this.list.map(t => t.checked = evt.target.checked)
    },

    get_foot_value(data) {
       if (data.label == 'Amount') {
        return '$'+this.total_amount.toLocaleString()
       }
       if (data.label == 'Due Date') {
        return 'Total:'
       }
    },

    get_reference(data) {
      if (data.cash_record) {
        if (data.cash_record.ref_type == 'DEAL') {
          return data.cash_record.deal.order_deal_id
        } else if (data.cash_record.ref_type == 'PM') {
          return data.cash_record.pm.pm_id
        } 
      }

      return ''
      
    }


  },

};
</script>

<template>
  <Layout>
    <b-modal centered id="modal-manual-transfer-dialog" title="Manual Transfer" size="lg" hide-footer>
      <ManualTransferDialog  @confirm="$bvModal.hide('modal-manual-transfer-dialog')" @cancel="$bvModal.hide('modal-manual-transfer-dialog')"/>
    </b-modal>

    
    <b-modal centered id="modal-make-transfer-dialog" title="Transfer Detail" size="lg" hide-footer>
      <MakeTransferDialog :transactions="selected_transaction_list"  @confirm="onTransfered" @cancel="$bvModal.hide('modal-make-transfer-dialog')"/>
    </b-modal>
              

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-3">
               
                 
                    <select
                        class="form-control"
                        data-trigger
                        name="account"
                        id="bank_account"
                        @change="changeAccount"
                        >
                        <option value=" "></option>
                      </select>
                 
               
              </div>
              <!-- end col -->

              <div class="col-md-9">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2"
                >

                
                <b-button variant="primary"  v-b-modal.modal-manual-transfer-dialog>Manually Transfer</b-button>
                <b-button variant="primary" :disabled="transfer_btn_flag" v-b-modal.modal-make-transfer-dialog>Make Transfer</b-button>
               

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <div class="card-body">



            <div class="table-responsive" v-if="this.list_type=='RECORDS'">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="list"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  class="table-check" foot-clone strip hover
                  style="min-height: 50dvh;"
                >

                <template #head(C)>
                    <input type="checkbox"  class="form-check-input" @change="onSelectAll($event)"/>
                  </template>

                <template #cell(C)="data">
                    <div class="form-check font-size-16">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="checkAll"
                        v-model="data.item.checked" 
                        @change="onRecordsUpdate($event, data.item)"
                      />
                      <label class="form-check-label" ></label>
                    </div>
                  </template>

                  <template  #cell(ID)="data">
                    {{ data.item.id}}
                  </template>
                  
                  <template #cell(From)="data"> 
                    {{ data.item.from.name }}
                  </template>
                  <template #cell(To)="data"> 
                    {{ data.item.to.name }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString()}}
                  </template>

                  <template #cell(DueDate)="data">             
                      {{ data.item.transaction_date }}
                  </template>
                  
                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>

                  <template #cell(Remark)="data">
                    {{ data.item.remark }}
                  </template>

                  <template #cell(Reference)="data">
                    <span v-if="data.item.cash_record.ref_type == 'EXPENSE' ">
                      <router-link :to="{ name: 'ap-expense-overview', query: { expense_id: data.item.cash_record.ref_id } }"
                        class="text-body fw-medium">{{ data.item.reference }}</router-link>
                    </span>
                    <span v-else-if="data.item.cash_record.ref_type == 'DEAL' ">
                      <router-link :to="{ name: 'deal-overview', query: { deal_id: data.item.reference } }"
                        class="text-body fw-medium">{{ data.item.reference }}</router-link>
                    </span>
                    <span v-else-if="data.item.cash_record.ref_type == 'PM' ">
                      <router-link :to="{ name: 'pm-overview', query: { pm_id: data.item.reference } }"
                        class="text-body fw-medium">{{ data.item.reference }}</router-link>
                    </span>
                    <span v-else>
                      {{ data.item.reference }}
                    </span>

                  
                  </template>

                  <template #foot()="data">
                    {{ get_foot_value(data) }}
                  </template>


                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start" v-if="this.list_type=='RECORDS'">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->   


          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
